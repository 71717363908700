<template>
  <b-container class="dashboad bg-light page-wrapper">
        <b-row class="main-content-wrapper" no-gutters>
            <b-col cols="12">
              <h3>Custom Tab Filter</h3>
            </b-col>
            <b-col cols="12">
              <custom-tab-filter :sections="sections" @submit="handleSubmit" :save-label="$t('common.save')"/>
            </b-col>
        </b-row>
  </b-container>
</template>

<script>
import CustomTabFilter from '@/components/common/CustomTabFilter'
export default {
  components: { CustomTabFilter },
  computed: {
    sections () {
      return [
        {
          label: this.$t('common.assets'),
          value: [],
          child: [
            {
              type: 'select',
              multiple: true,
              options: [
                { text: 'Overview', value: 'overview' },
                { text: 'Groups', value: 'groups' },
                { text: 'Types', value: 'types' },
                { text: 'General', value: 'general' }
              ]
            }
          ]
        },
        {
          label: this.$t('common.liabilities'),
          value: [],
          child: [
            {
              type: 'select',
              multiple: true,
              options: [
                { text: 'Overview', value: 'overview' },
                { text: 'Groups', value: 'groups' },
                { text: 'Types', value: 'types' },
                { text: 'General', value: 'general' }
              ]
            }
          ]
        }
      ]
    }
  },
  methods: {
    handleSubmit: function (values) {
      console.log(values)
    }
  }
}
</script>
